import { API_URL } from 'react-native-dotenv';
import { GetOidAndAccessToken } from './utils/B2CAuth_utils';

const urlPathForTranslation = encodeURIComponent(window.location.pathname.replace(/\d+/g, ''));
const regexTranslationTextCheck = /^(?=.*\p{L})[\p{L}\p{N}\s\p{P}+]+$/u;
// This regex determines that a string has only letters, numbers, spaces, and punctuation (plus '+'). If it does, then it is considered to be valid for translation.
// It also has a positive lookahead assertion that ensures there is at least one language letter somewhere in the string, we don't want to translate only numbers and/or punctuation.

let startingLanguageId = null;
let selectedLanguageId = null;
let textContentToAlwaysTranslate = [];
let cssSelectorsToNeverTranslate = [];
let languagesAvailable = [];

let mutatedQueue = [];
let batchSetOfNodesToWalk = new Set();
let translateThrottleTimeout = null;
let lastBatchTranslateTime = 0;
let nextBatchTranslateAvailableTime = 0;
const batchTranslationThrottleIntervalMs = 500;
let UserIsLoggedIn = true; // we are authenticating within the API, so no invalid calls should be made. This is hard coded to true for ease of development.
const querySelectorsToInstantlyTranslate = ['.field-hint-error, .custom-tooltip', '.tooltip-hint', '.k-input-value-text'];
const shadowRootsBeingWatched = new Set();

//#region Mutation Observer and Translation Setup

function getPreciseTime(time) {
    if (time == 0) {
        return 'no time set yet'
    }
    const date = new Date(time);
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

    return `${seconds}.${milliseconds}s of min`;
    // Output will be something like "3:30:25.5PM"
}

const scheduleBatchTranslate = () => {
    // Many mutations can happen at once, so we want to throttle the translate function to only run once per second with a batch set of nodes to walk
    if (batchSetOfNodesToWalk.size > 0) {
        let timeSinceLastBatch = Date.now() - lastBatchTranslateTime;
        if (timeSinceLastBatch > batchTranslationThrottleIntervalMs) { // If it's been more than a second since the last batch
            console_log('batchTranslate() called immidiately because the last call was over ', batchTranslationThrottleIntervalMs, 'ms ago which is the batchTranslationThrottleIntervalMs value' );
            batchTranslate(); // Execute immediately. Uses global batchSetOfNodesToWalk variable.
        } else if (!translateThrottleTimeout) {
            let timeUntilNextBatch = nextBatchTranslateAvailableTime - Date.now();
            if (timeUntilNextBatch < 0) {
                console.error('timeUntilNextBatch in scheduleBatchTranslate() was negative ', timeUntilNextBatch, ' that that should happen, there is probably something wrong with this logic');
            }
            console_log('scheduleBatchTranslate() queued up a batchTranslate() to execute in ', timeUntilNextBatch, 'ms');
            translateThrottleTimeout = setTimeout(() => batchTranslate(), timeUntilNextBatch);
        } else {
            console_log('scheduleBatchTranslate() ended for performance reasons. Next batch already queued.', 'lastBatchTranslateTime:', getPreciseTime(lastBatchTranslateTime), ' nextBatchTranslateAvailableTime:', getPreciseTime(nextBatchTranslateAvailableTime));
        }
    } else {
        console_log('scheduleBatchTranslate() ended for performance reasons. scheduleBatchTranslate() called but no nodes batched. ', 'lastBatchTranslateTime:', getPreciseTime(lastBatchTranslateTime), ' nextBatchTranslateAvailableTime:', getPreciseTime(nextBatchTranslateAvailableTime));
    }
}

const batchTranslate = () => {
    console_log('batchTranslate() called with nodes: ', batchSetOfNodesToWalk, ' it is calling translate() now.');
    translate(batchSetOfNodesToWalk);
    batchSetOfNodesToWalk = new Set();
    lastBatchTranslateTime = Date.now();
    nextBatchTranslateAvailableTime = lastBatchTranslateTime + batchTranslationThrottleIntervalMs;
    translateThrottleTimeout = null;
}

const filterMutationsAndGetNodes = (mutations) => {
    let nodes = new Set();
    if (selectedLanguageId != startingLanguageId) {
        const idsToIgnore = ['adminSiteTranslateLanguageDropdown', 'adminSiteTranslateContainer', 'translateGlobeIcon', 'adminSiteTranslateLanguageDropdownArrow', 'adminSiteTranslateSelectedOption', 'Input_UserSelectedLanguage', 'adminSiteTranslateDropdownOptions'];
        let newlyAddedNodes = [];
        const notTranslateRelatedMutation = mutations.filter((mutation) => {
            if (idsToIgnore.includes(mutation?.target?.id)) {
                return false;
            }
            const index = mutatedQueue.findIndex(queueItem => queueItem.node == mutation?.target);
            if (index > -1) {
                mutatedQueue.splice(index, 1); // Remove one occurrence from the queue
                return false;
            }
            if (mutation.type == 'childList') {
                mutation.addedNodes.forEach(node => {
                    newlyAddedNodes.push(node);
                });
                return false;
            }
            return true;
        });

        
        notTranslateRelatedMutation.filter(mutation => mutation?.target).forEach(mutation => {
            nodes.add(mutation.target);
        });
        newlyAddedNodes.forEach(node => {
            nodes.add(node);
        });
    }
    return nodes;
};

const addNodeToMutationQueue = (node, type) => {
    mutatedQueue.push({
        node: node,
        type: type
    });
}

const translationMutationObserver = new MutationObserver((mutations) => {
    var relevantNodeSet = filterMutationsAndGetNodes(mutations);
    if (relevantNodeSet.size > 0) {
        var parentsOfSubtreesToInstantlyTranslate = [...new Set([...document.querySelectorAll(querySelectorsToInstantlyTranslate)])];
        relevantNodeSet = new Set([...relevantNodeSet].filter(n => {
            // We don't want to batch translate nodes that are in subtrees of nodes being translated instantly. This filters them out.
            return !parentsOfSubtreesToInstantlyTranslate.some(parentNode => parentNode == n || parentNode.contains(n))
        }));
        if (parentsOfSubtreesToInstantlyTranslate.length > 0) {
            console_log('Mutation observer found nodes that need to be translated instantly, skipping scheduleBatchTranslate() and running translate()', parentsOfSubtreesToInstantlyTranslate);
            translate(parentsOfSubtreesToInstantlyTranslate);
        }
        if (relevantNodeSet.size > 0) {
            relevantNodeSet.forEach(node => { batchSetOfNodesToWalk.add(node) });
            scheduleBatchTranslate();
        } else {
            console_log('Mutation observer had non-translate nodes, but all needed to be translated instantly, no scheduleBatchTranslate() ran, MutationObserver ends.');
        }
    }
});

document.addEventListener('DOMContentLoaded', async function () {
    translationMutationObserver.observe(document.body, { childList: true, subtree: true, characterData: true, attributes: true, shadowRoot: true });
    console_log('Mutation observer started.');
    const { oid, accessToken } = await GetOidAndAccessToken();
    if(oid && accessToken){
        await getNewTranslateData();
    }
});

const getNewTranslateData = async () => {
    let newDataRequested =
    !localStorage.getItem('textContentToAlwaysTranslate')
    || !localStorage.getItem('cssSelectorsToNeverTranslate')
    || !localStorage.getItem('languagesAvailable')

    const { oid, accessToken } = await GetOidAndAccessToken();

    await fetch(`${API_URL}/api/SiteTranslation/GetTranslateInfo?newDataRequested=${newDataRequested}&lastCachedTimestamp=${localStorage.getItem('lastCachedTimestamp') ?? 0}`, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `Bearer ${accessToken}`
        })
    }).then(async response => {
        if(!response.ok){
            throw new Error('Error fetching translation information from the server');
        } 

        var result = await response.json();
        
        if (result.upToDate) {
            textContentToAlwaysTranslate = JSON.parse(localStorage.getItem('textContentToAlwaysTranslate'));
            cssSelectorsToNeverTranslate = JSON.parse(localStorage.getItem('cssSelectorsToNeverTranslate'));
            languagesAvailable = JSON.parse(localStorage.getItem('languagesAvailable'));
        } else {
            textContentToAlwaysTranslate = result.textContentToAlwaysTranslate;
            localStorage.setItem('textContentToAlwaysTranslate', JSON.stringify(textContentToAlwaysTranslate));
            cssSelectorsToNeverTranslate = result.cssSelectorsToNeverTranslate;
            localStorage.setItem('cssSelectorsToNeverTranslate', JSON.stringify(cssSelectorsToNeverTranslate));
            languagesAvailable = result.languagesAvailable;
            localStorage.setItem('languagesAvailable', JSON.stringify(languagesAvailable));
            localStorage.setItem('lastCachedTimestamp', (new Date()).getTime());
        }
        selectedLanguageId = result.userSelectedLanguageId;
        if (!selectedLanguageId) {
            selectedLanguageId = result.startingLanguageId; 
        }
    
        startingLanguageId = result.startingLanguageId; //this should be english

        if (languagesAvailable.length === 0) {
            return console_log('No languages available to translate to, exiting.');
        }

        if (selectedLanguageId != startingLanguageId) {
            translate();
        } else {
            TranslateFinished();
        }
    }).catch(error => {
        console.error('Error fetching translation information from the server', error)
    });
}

//#endregion

//#region Translation Caching

function addCachedTranslation(node, translatedText) { // the translatedText parameter should digit placeholders if it has any digits
    const expirationDate = new Date().getTime() + 72 * 60 * 60 * 1000; // 3 days from now
    const storedValue = { translation: translatedText, expirationDate };
    let sourceText = getSourceTextToTranslateFromNode(node);
    if (/\d/.test(sourceText)) {
        const [sourceTextWithDigitPlaceholders] = replaceDigitsWithPlaceholders(sourceText);
        sourceText = sourceTextWithDigitPlaceholders;
    }
    localStorage.setItem(`${selectedLanguageId}|||${sourceText}`, JSON.stringify(storedValue));
}

function getCachedTranslation(key) {
    const keyLanguageId = parseInt(key.split('|||')[0]);
    const keySourceText = key.split('|||')[1];
    const hasDigit = /\d/.test(keySourceText);
    let digitMap = null;
    if (hasDigit) {
        let [keySourceTextWithDigitPlaceholders, returnedDigitMap] = replaceDigitsWithPlaceholders(keySourceText);
        key = `${keyLanguageId}|||${keySourceTextWithDigitPlaceholders}`;
        digitMap = returnedDigitMap;
    }
    var storedValueJSON = localStorage.getItem(key);
    if (!storedValueJSON) return null;

    const storedValue = JSON.parse(storedValueJSON);
    const now = new Date().getTime();

    if (now > storedValue.expirationDate) {
        localStorage.removeItem(key);
        return null; // Return null or other indicator that the value was expired
    }

    if (hasDigit) {
        return restoreDigits(storedValue.translation, digitMap);
    } else {
        return storedValue.translation;
    }
}

function applyCachedTranslations(nodesToTranslate) {
    var cachedIndexes = [];
    nodesToTranslate.forEach((node, index) => {
        let text = getSourceTextToTranslateFromNode(node);
        let cachedTranslation = getCachedTranslation(`${selectedLanguageId}|||${text}`);
        if (cachedTranslation) {
            setNodeTranslation(node, cachedTranslation);
            cachedIndexes.push(index);
        }
    })
    return nodesToTranslate.filter((node, index) => !cachedIndexes.includes(index));
}

//#endregion

let stopTranslating = false;
function resetTranslation() {
    // Developer tool to reset the translation cache
    stopTranslating = true;
    localStorage.clear();
    window.location.reload();
}

async function translate(nodesToWalk = new Set([document.body]), languageIdToTranslateTo = selectedLanguageId) {
    if (stopTranslating) return;
    let nodesToTranslate = getNodesToTranslate(nodesToWalk);
    if (nodesToTranslate.length > 0) {
        console_log('translate() called. Here are the acceptable translation nodes after tree traversal: ', 'texts: ', nodesToTranslate.map(n => getSourceTextToTranslateFromNode(n)), ' nodes:', nodesToTranslate);
    } else {
        console_log('translate() called but there were no acceptable translation nodes after tree traversal. translate() ends.');
        TranslateFinished();
        return;
    }

    if(languageIdToTranslateTo && languageIdToTranslateTo != selectedLanguageId){
        selectedLanguageId = languageIdToTranslateTo;
    }

    if (selectedLanguageId === startingLanguageId /* probably english, but not for sure */ ) {
        nodesToTranslate.forEach((node) => {
            setNodeTranslation(node, getSourceTextToTranslateFromNode(node));
        });
        TranslateFinished();
        console_log('Page reverted back to source language using stateful DOM attributes. translate() ends.');
        return;
    }

    nodesToTranslate = applyCachedTranslations(nodesToTranslate);

    if (nodesToTranslate.length === 0) {
        console_log('after applying cache and removing previously requested node translations, no nodes left. translate() ends.');
        TranslateFinished();
        return;
    }

    let digitMaps = [];

    const requestedTranslations = nodesToTranslate.map((node) => {
        let text = getSourceTextToTranslateFromNode(node);
        if (/\d/.test(text)) {
            let [textWithPlaceholder, digitMap] = replaceDigitsWithPlaceholders(text);
            digitMaps.push(digitMap);
            text = textWithPlaceholder;
        } else {
            digitMaps.push(null);
        }
        let translationObject = {
            Text: text,
            IsLayoutTranslation: node.parentNode?.getAttribute("data-is-layout-translation") === "true" || (node.getAttribute && node.getAttribute("data-is-layout-translation") === "true"),
            IsLanguageDescription: node?.parentNode?.parentNode?.id == "adminSiteTranslateDropdownOptions",
        }
        return translationObject;
    });

    console_log('after applying cache and removing previously requested node translations, here are the accepted translation nodes: ', nodesToTranslate, '. here is the api payload: ', requestedTranslations);

    const { oid, accessToken } = await GetOidAndAccessToken();

    fetch(`${API_URL}/api/SiteTranslation/GetCachedTranslationsAndGetStaleOrMissingTranslations?userSelectedLanguageId=${selectedLanguageId}&urlPath=${urlPathForTranslation}`, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `Bearer ${accessToken}`
        }),
        body: JSON.stringify(requestedTranslations)
    }).then(async response => {
        if(!response.ok){
            throw new Error('Error fetching translation information from the server');
        }
        var result = await response.json();
        
        let newTranslationsNeeded = [];
        let newTranslationsNeededNodes = [];
        let newDigitMaps = [];
        result.translations.forEach(function (translation, index) {
            if (translation.needsNewTranslation) {
                translation.text = requestedTranslations[index].Text;
                newTranslationsNeeded.push(translation);
                newTranslationsNeededNodes.push(nodesToTranslate[index]);
                if (digitMaps[index] !== null) {
                    newDigitMaps.push(digitMaps[index]);
                } else {
                    newDigitMaps.push(null)
                }
            } else {
                let text = translation.text;
                const targetNode = nodesToTranslate[index];
                const digitMapForThisNode = digitMaps[index];
                if (digitMapForThisNode !== null) {
                    text = restoreDigits(text, digitMapForThisNode)
                }
                setNodeTranslation(targetNode, text);
                addCachedTranslation(targetNode, translation.text);
            }
        });
        digitMaps = newDigitMaps;
        console_log('Recieved translations back from the database cache: ', result.translations);
        
        let SecretTranslationPassword = getCookie("SecretTranslationPassword");
        if (newTranslationsNeeded.length > 0 && (SecretTranslationPassword || UserIsLoggedIn)) {
            console_log('Database does not have translations for these, sending another api request ', newTranslationsNeeded, '. nodes:', newTranslationsNeededNodes);

            fetch(`${API_URL}/api/SiteTranslation/TranslateTextGetResult?userSelectedLanguageId=${selectedLanguageId}&urlPath=${urlPathForTranslation}`, {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json; charset=utf-8',
                    'Authorization': `Bearer ${accessToken}`
                }),
                body: JSON.stringify(newTranslationsNeeded)
            }).then(async apiResponse => {
                if(!apiResponse.ok){
                    throw new Error('Error fetching translation information from the server');
                } 
                var response = await apiResponse.json();
                
                if (response.message) {
                    console.error(response.message, '. translate() ends.')
                    TranslateFinished();
                    return;
                }
                response.translationResults.forEach(function (translation, index) {
                    let text = translation.text;
                    const digitMapForThisNode = digitMaps[index];
                    if (digitMapForThisNode !== null) {
                        text = restoreDigits(text, digitMapForThisNode)
                    }
                    const targetNode = newTranslationsNeededNodes[index];
                    setNodeTranslation(targetNode, text);
                    addCachedTranslation(targetNode, translation.text);
                });
                if (response.errors.length > 0) {
                    response.errors.forEach(function (error) { console.error(error) });
                } else {
                    console_log('translations created, added to database, and applied to DOM ', response.translationResults, '. making a final API call to translate the text to other admin languages if needed for other users. ');
                }
                
                fetch(`${API_URL}/api/SiteTranslation/TranslateOtherLanguageSiteTranslations?userSelectedLanguageId=${selectedLanguageId}`, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/json; charset=utf-8',
                        'Authorization': `Bearer ${accessToken}`
                    }),
                    body: JSON.stringify({
                        SourceSiteTranslationsProcessed: response.sourceSiteTranslationsProcessed
                    }),
                }).then(response => {
                    console_log('All background translations for other user languages are now finished, translate() ends.');
                    TranslateFinished();
                });
            });
        } else {
            console_log('No translations were missing from the database, translate() ends. ');
            TranslateFinished();
        }
    }).catch(error => {
        console.error('Error fetching translations from the server', error)
    });
}

//#region Translation Helpers

function setNodeTranslation(node, translation) {
    if (checkNodeHasPlaceholder(node)) {
        addNodeToMutationQueue(node, 'placeholder');
        node.placeholder = translation;
        addNodeToMutationQueue(node, 'attributes');
        node.setAttribute('data-last-translated-placeholder', translation);
    } else if (checkNodeHasTitle(node)) {
        addNodeToMutationQueue(node, 'title');
        node.title = translation;
        addNodeToMutationQueue(node, 'attributes');
        node.setAttribute('data-last-translated-title', translation);
    } else {
        addNodeToMutationQueue(node, 'characterData');
        node.nodeValue = translation;
        addNodeToMutationQueue(node.parentNode, 'attributes');
        node.parentNode?.setAttribute('data-last-translated-value-' + getNodeIndexOfParent(node), translation);
    }
}

function getTranslateTextFromNode(node) {
    if (checkNodeHasPlaceholder(node)) {
        return getSanitizedString(node.placeholder);
    } else if (checkNodeHasTitle(node)) {
        return getSanitizedString(node.title);
    } else {
        return getSanitizedString(node.nodeValue);
    }
}

function getSourceTextToTranslateFromNode(node) {
    let nodeIndexOfParent = getNodeIndexOfParent(node);

    if (nodeSourceChanged(node)) {
        return getTranslateTextFromNode(node);
        // if the value of the node has changed since it was last translated, probably means the node got replaced with new source text,
        // so just return the current value of the node to be translated
    }
    if (node.parentNode && node.parentNode.getAttribute("data-source-translation-" + nodeIndexOfParent)) {
        return node.parentNode.getAttribute("data-source-translation-" + nodeIndexOfParent);
    }
    else if (checkNodeHasPlaceholder(node)) {
        if (node.getAttribute("data-source-translation-placeholder")) {
            return node.getAttribute("data-source-translation-placeholder")
        } else {
            return getSanitizedString(node.placeholder);
        }
    } else if (checkNodeHasTitle(node)) {
        if (node.getAttribute("data-source-translation-title")) {
            return node.getAttribute("data-source-translation-title")
        } else {
            return getSanitizedString(node.title);
        }
    }
    else if (node.nodeValue) {
        return getSanitizedString(node.nodeValue);
    }
    return '';
}

function getNodeIndexOfParent(childNode) {
    if (!childNode.parentNode) {
        return 0;
    }
    for (let i = 0; i < childNode.parentNode.childNodes.length; i++) {
        if (childNode.parentNode.childNodes[i] === childNode) {
            return i;
        }
    }
}

function getLastTranslatedValueFromNode(node) {
    if (checkNodeHasPlaceholder(node)) {
        return node.getAttribute('data-last-translated-placeholder');
    } else if (checkNodeHasTitle(node)) {
        return node.getAttribute('data-last-translated-title');
    } else {
        return node.parentNode && node.parentNode.getAttribute('data-last-translated-value-' + getNodeIndexOfParent(node));
    }
}

function nodeSourceChanged(node) {
    let currentText = getTranslateTextFromNode(node);
    let lastText = getLastTranslatedValueFromNode(node);
    let lastTextHasValue = lastText && lastText.length > 0;
    return lastTextHasValue && lastText !== currentText;
}

function getNodesToTranslate(nodesToWalk = new Set([document.body])) {
    // Remove nodes that are descendants of other nodes in the list
    let rootNodesToWalk = Array.from(nodesToWalk).filter((node, index, allNodes) => {
        return !allNodes.some(existingNode => existingNode !== node && existingNode.contains(node));
    });

    let acceptedNodes = new Set();
    let shadowRootNodesToAdd = [];
    // get all shadow roots on the page
    rootNodesToWalk.forEach(function (node) {
        if(node.shadowRoot != null) {
            shadowRootNodesToAdd.push(node.shadowRoot);
        }
        let shadowWalker = document.createTreeWalker(node, NodeFilter.SHOW_ELEMENT, { acceptNode: function (node) { return NodeFilter.FILTER_ACCEPT; } }, false);
        while (shadowWalker.nextNode()) {
            if(shadowWalker.currentNode.shadowRoot != null) {
                shadowRootNodesToAdd.push(shadowWalker.currentNode.shadowRoot);
            }
        }
    });
    // make sure we are watching all shadow roots for changes to translate
    shadowRootNodesToAdd.forEach(function (node) {
        if(!shadowRootsBeingWatched.has(node)) {
            translationMutationObserver.observe(node, { childList: true, subtree: true, characterData: true, attributes: true, shadowRoot: true });
            shadowRootsBeingWatched.add(node);
        }
    });
    rootNodesToWalk = rootNodesToWalk.concat(shadowRootNodesToAdd);
    // walk the tree and get all nodes that need to be translated while applying attribute changes to the DOM
    rootNodesToWalk.forEach(function (node) {
        //need to check root node first because tree walker will not return the root node
        if (node != document.body && treeWalkerTranslateFilter.acceptNode(node) === NodeFilter.FILTER_ACCEPT) {
            acceptedNodes.add(node);
        }
        let walker = document.createTreeWalker(node, NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT, treeWalkerTranslateFilter, false);
        while (walker.nextNode()) {
            acceptedNodes.add(walker.currentNode);
        }
    });
    return Array.from(acceptedNodes);
}

function hasNoTranslateAncestorOrInvalidParentNode(node) {
    let ancestor = node.parentNode;
    let continueOn = true;
    while (ancestor && continueOn) {
        if (ancestor.getAttribute && ancestor.getAttribute('data-no-translate') == 'true') {
            return true;
        }
        if (ancestor.nodeName && ['script', 'style', 'noscript', 'svg', 'code',
            'pre', 'img', 'input', 'textarea', 'iframe', 'abbr',
            'acronym', 'dfn', 'var', 'samp', 'sup', 'sub', 'math'].includes(ancestor.nodeName.toLowerCase())) {
            return true;
        }
        if(ancestor.parentNode)
            ancestor = ancestor.parentNode;
        else
            continueOn = false;
    }
    if (ancestor != document && !(ancestor instanceof DocumentFragment)) {
        // if highest ancestor is not attached to the DOM/document, skip it. Seems to happen when search kendo grids, and when the row doesn't belong to the .k-grid anymore it won't match the CSS selector to prevent translation, so this fixes that case.
        return true;
    }
    return false;
}

function matchesSomeNoTranslateSelector(node) {
    let nodeToCheck = node;
    while (nodeToCheck.nodeType !== Node.ELEMENT_NODE && nodeToCheck.parentNode) {
        nodeToCheck = nodeToCheck.parentNode;
    }
    if (!nodeToCheck.parentNode) {
        // node true is not attached to the DOM so return true to skip it
        return true;
    }
    return cssSelectorsToNeverTranslate.some(selector => nodeToCheck.matches(selector));
}

var treeWalkerTranslateFilter = {
    acceptNode: function (node) {
        const isTextNode = node.nodeType === Node.TEXT_NODE;
        const isElementWithPlaceholder = checkNodeHasPlaceholder(node);
        const isElementWithTitle = checkNodeHasTitle(node);
        if (!isTextNode && !isElementWithPlaceholder && !isElementWithTitle) {
            return NodeFilter.FILTER_SKIP;
        }
        const sourceText = getSourceTextToTranslateFromNode(node);
        const isAnAlwaysTranslatedText = textContentToAlwaysTranslate.includes(sourceText?.replace(/\d+/g, '')?.trim()) && window.location.pathname !== '/Settings/TranslationSettings';
        if ((hasNoTranslateAncestorOrInvalidParentNode(node) || matchesSomeNoTranslateSelector(node)) && !isAnAlwaysTranslatedText) {
            return NodeFilter.FILTER_SKIP;
        }
        if (isTextNode) {
            if (sourceText.length == 0 || !regexTranslationTextCheck.test(sourceText)) {
                return NodeFilter.FILTER_SKIP;
            }
            const nodeIndexOfParent = getNodeIndexOfParent(node);
            addNodeToMutationQueue(node.parentNode, 'attributes');
            node.parentNode.setAttribute("data-source-translation-" + nodeIndexOfParent, sourceText); //before node accepted, set the source translation attribute for next run
        } else if (isElementWithPlaceholder) {
            addNodeToMutationQueue(node, 'placeholder');
            node.setAttribute("data-source-translation-placeholder", sourceText);
        } else if (isElementWithTitle) {
            addNodeToMutationQueue(node, 'title');
            node.setAttribute("data-source-translation-title", sourceText);
        }
        return NodeFilter.FILTER_ACCEPT;
    }
};

function checkNodeHasPlaceholder(node) {
    return (node.nodeName && node.placeholder && node.placeholder.trim() != '')
        && ('textarea' === node.nodeName.toLowerCase() || ('input' === node.nodeName.toLowerCase() && ['text', 'password', 'email', 'number', 'search', 'tel', 'url'].includes(node.type.toLowerCase())));
}

function checkNodeHasTitle(node) {
    return ((typeof node.title !== 'undefined') && node.title.trim() !== '');
}

function replaceDigitsWithPlaceholders(str) {
    let digitMap = new Map();
    let placeholderIndex = 0;
    let stringWithPlaceholders = str.replace(/\d+/g, (match) => {
        digitMap.set(placeholderIndex.toString(), match);
        return placeholderIndex++;
    });
    return [stringWithPlaceholders, digitMap];
}

function restoreDigits(str, digitMap) {
    return str.replace(/\d+/g, (match) => {
        return digitMap.get(match) || match;
    });
}

function handleLogoutForTranslation() {
    selectedLanguageId = languagesAvailable.find(l => l.languageDescription === 'English').languageId ?? startingLanguageId;
    translate();
}

function TranslateFinished() {
    
}

function getSanitizedString(val) {
    return val?.replace(/\s+/g, ' ');
}

function getCookie(name) {
    var cookieArr = document.cookie.split("; ");
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        if (name === cookiePair[0]) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return null;
}

let translateDebugMode = localStorage.getItem('translateDebugMode') === 'true';
function toggleTranslateDebugMode() {
    localStorage.setItem('translateDebugMode', !translateDebugMode);
}

function console_log(...args) {
    if (translateDebugMode) {
        console.log(...args);
    }
}

//#endregion

export { getNewTranslateData, translate, resetTranslation, toggleTranslateDebugMode, handleLogoutForTranslation };