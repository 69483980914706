import { useB2CAuthContext } from "../context/B2CAuthContext";
import { GetUsername, GetOidAndAccessToken } from "./B2CAuth_utils";


const LogActivity = async (activity, description , apiUri) => {
    try {
        const {oid, accessToken} = await GetOidAndAccessToken();
        const data = {
            Activityid: activity,
            ActivityDescription: description,
            UserAgent: navigator.userAgent,
            UsingPwa: ('standalone' in window.navigator && window.navigator.standalone) || window.matchMedia('(display-mode: standalone)').matches,
            AddUserName: oid ?? await GetUsername(),
            NetworkId: null, //TODO: update to use correct neighbor once networks are added
            RequestUrl: window.location.href.toLowerCase(),
        };

        const response = await fetch(`${apiUri}/api/ActivityLog?oid=${oid}`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json; charset=utf-8'
            }),
            body: JSON.stringify(data)
        });

        if (!response.ok)
            throw `API response ${response.status} ${response.statusText}`

    } catch (error) {
        console.error('ERROR:', error);
    }
};

const Activity = {
    AppViewMap: 19,
    AppSignUpStarted: 20,
    AppSignUpCompleted: 21,
    AppLoginSuccessful: 22,
    AppFailedLoginAttempt: 23,
    AppUpdateProfile: 24,
    AppUpdatePassword: 25,
    AppUpdateUserName: 26,
    AppSubmitFeedback: 27,
    AppViewSurvey: 28,
    AppSurveyStarted: 29,
    AppSurveyCompleted: 30,
    AppViewNotifications: 31,
    AppViewTerms: 32,
    AppViewPrivacy: 33,
    AppViewResourceHub: 36,
    AppClickSurveyBenefitsLink: 37
}

export {
    LogActivity,
    Activity
};