// useFeatureFlags.js
import { GetOidAndAccessToken } from "../utils/B2CAuth_utils";
import AsyncStorage from "@react-native-async-storage/async-storage";

const loadFeatureFlags = async (apiUri) => {
  try {
      const {oid, accessToken} = await GetOidAndAccessToken();
      const response = await fetch(`${apiUri}/api/FeatureFlag/GetFeatureFlagsForNeighbor`, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${accessToken}`
        }),
      });

      if (!response.ok)
          throw `API response ${response.status} ${response.statusText}`

      const newFeatures = await response.json();
      await AsyncStorage.setItem('features', JSON.stringify(newFeatures));
  } catch (error) {
      console.error('ERROR:', error);
  }
};

const isFeatureEnabled = async (featureName) => {
  try {
    const jsonString = await AsyncStorage.getItem('features');
    const features = jsonString ? JSON.parse(jsonString) : null;
    const feature = features ? features.find(f => f.name === featureName) : null;
    return feature ? feature.enabled : false;
  } catch (error) {
      return false;
  }
}

export {
  loadFeatureFlags,
  isFeatureEnabled
};
