import { ActivityIndicator, ScrollView, StyleSheet } from "react-native";

export default function LoadingPage()
{
    return (
        <ScrollView contentContainerStyle={styles.flexContainer}>
            <ActivityIndicator size="large" color="#00483d"/>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    flexContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#e1ffb3',
    },
});