import AsyncStorage from '@react-native-async-storage/async-storage';
import { jwtDecode } from 'jwt-decode';
import { API_URL, APPLICATION_SERVER_KEY } from "react-native-dotenv"

const GetNeighbor = async () => {
    try {
        const storageTokens = await AsyncStorage.getItem('tokens');
        if (storageTokens == null)
            return null;
        
        const tokenResponse = JSON.parse(storageTokens);
        const idToken = tokenResponse.idToken;
        const accessToken = tokenResponse.accessToken;
        const oid = jwtDecode(idToken).sub;

        const response = await fetch(`${API_URL}/api/Neighbor/${oid}`, {
            method: 'GET',
            headers: new Headers({
                'Authorization': `Bearer ${accessToken}`
            })
        });
    
        if (!response.ok)
            throw `API response ${response.status} ${response.statusText}`
    
        return await response.json();
    } catch (error) {
        console.error('ERROR:', error);
        return null;
    }
}

const UpdateNeighbor = async (neighbor) => {
    try {
        const storageTokens = await AsyncStorage.getItem('tokens');
        if (storageTokens == null)
            return null;
        
        const tokenResponse = JSON.parse(storageTokens);
        const accessToken = tokenResponse.accessToken;

        const response = await fetch(`${API_URL}/api/Neighbor/${neighbor.neighborId}`, {
            method: 'POST',
            headers: new Headers({
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(neighbor)
        });
    
        if (!response.ok)
            throw `API response ${response.status} ${response.statusText}`
    
        return neighbor;
    } catch (error) {
        console.error('ERROR:', error);
        return null;
    }
}

const UpdateNeighborPushSubscription = async (neighbor, subscription = null) => {
    let updatedNeighbor = structuredClone(neighbor);   
    if (subscription) {
        const subscriptionWithKeys = JSON.parse(JSON.stringify(subscription));
        updatedNeighbor.sendPushInd = true;
        updatedNeighbor.pushEndpoint = subscriptionWithKeys.endpoint;
        updatedNeighbor.pushAuth = subscriptionWithKeys.keys.auth;
        updatedNeighbor.pushP256DH = subscriptionWithKeys.keys.p256dh;
    } else {
        updatedNeighbor.sendPushInd = false;
        updatedNeighbor.pushEndpoint = null;
        updatedNeighbor.pushAuth = null;
        updatedNeighbor.pushP256DH = null;
    }

    if (updatedNeighbor.sendPushInd === neighbor.sendPushInd &&
        updatedNeighbor.pushEndpoint === neighbor.pushEndpoint &&
        updatedNeighbor.pushAuth === neighbor.pushAuth &&
        updatedNeighbor.pushP256DH === neighbor.pushP256DH    
    )
        return console.info('No subscription update needed');

    await UpdateNeighbor(updatedNeighbor);
}

export { GetNeighbor, UpdateNeighbor, UpdateNeighborPushSubscription };