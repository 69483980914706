import { TokenResponse, exchangeCodeAsync } from 'expo-auth-session';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { jwtDecode } from 'jwt-decode';
import { Activity, LogActivity } from './ActivityLogging_utils';
import {
  CHANGE_PASSWORD_REQUEST_URL,
  CHANGE_USERNAME_REQUEST_URL,
  CHANGE_PHONENUMBER_REQUEST_URL,
  APPLICATION_SERVER_KEY,
  API_URL
} from "react-native-dotenv"
import { GetNeighbor, UpdateNeighborPushSubscription } from './Neighbor_utils';
import { loadFeatureFlags } from './FeatureFlags_utils';
import { getNewTranslateData, handleLogoutForTranslation } from '../pageTranslation';

const SignInUp = async (authContext) => {
    try {
        const codeResponse = await authContext.promptAsync();
        if (!authContext.request || codeResponse?.type !== 'success' || !authContext.discovery)
          throw "code response error";
        const exchangeResponse = await exchangeCodeAsync(
          {
              clientId: authContext.clientId,
              scopes: ['https://nectarnow.com/api/access_as_user', 'openid', 'profile', 'email', 'offline_access'],
              redirectUri: authContext.redirectUri,
              code: codeResponse.params.code,
              extraParams: {
                grant_type: 'authorization_code',
                code_verifier: authContext.request.codeVerifier
              }         
          },
          authContext.discovery,
        );
        const tokenResponse = new TokenResponse(exchangeResponse);
        AsyncStorage.setItem('tokens', JSON.stringify(tokenResponse));

        const claims = jwtDecode(tokenResponse.idToken);
        let activity = Activity.AppLoginSuccessful
        if (claims.isForgotPassword)
          activity = Activity.AppUpdatePassword;
        if (claims.newUser)
          activity = Activity.AppSignUpCompleted;
        await LogActivity(activity, '', authContext.apiUri);

        const registration = await navigator.serviceWorker.getRegistration();
        if (registration && claims.sub && authContext.apiUri && tokenResponse.accessToken)
          registration.active.postMessage({
            Oid: claims.sub, 
            ApiUri: authContext.apiUri,
            AccessToken: tokenResponse.accessToken,
          });

        UpdatePushSubscription();
        await loadFeatureFlags(authContext.apiUri);
        getNewTranslateData();
        return true;
    } catch (error) {
        console.error('ERROR:', error);
        return false;
    }
}

const SignOut = async (authContext) => {
    try {
      const parameters = new URLSearchParams({
        client_id: authContext.clientId,
        post_logout_redirect_uri: authContext.redirectUri
      });
      
      AsyncStorage.removeItem('tokens');
      AsyncStorage.removeItem('hasSeenNotificationEngagementModal')
      AsyncStorage.removeItem('features');

      const logoutUrl = `${authContext.requestUri}/logout?${parameters}`;
      await authContext.promptAsync({url: logoutUrl});
      handleLogoutForTranslation();

      authContext.dispatchForB2C({ type: 'LOGGED_OUT' });
      return true;
    } catch (error) {
      console.error('ERROR:', error);
      return false;
    }
  }

  const ChangePassword = async (authContext) => {
    try {
      const parameters = new URLSearchParams({
        code_challenge: authContext.request?.codeChallenge,
        nonce: 'defaultNonce',
        code_challenge_method: authContext.request?.codeChallengeMethod,
        redirect_uri: authContext.redirectUri,
        client_id: authContext.clientId,
        response_type: 'id_token',
        state: authContext.request?.state,
        scope: 'openid',
        prompt: 'login',
      });
      const changePasswordUrl = `${CHANGE_PASSWORD_REQUEST_URL}/authorize?${parameters}`;

      console.log(changePasswordUrl);
      await authContext.promptAsync({url: changePasswordUrl});
      return true;
    } catch (error) {
      console.error('ERROR:', error);
      return false;
    }
  }

  const ChangeUsername = async (authContext) => {
    try {
      const parameters = new URLSearchParams({
        code_challenge: authContext.request?.codeChallenge,
        nonce: 'defaultNonce',
        code_challenge_method: authContext.request?.codeChallengeMethod,
        redirect_uri: authContext.redirectUri,
        client_id: authContext.clientId,
        response_type: 'id_token',
        state: authContext.request?.state,
        scope: 'openid',
        prompt: 'login',
      });
      console.log('LOG:', changeUsernameUrl);
      const changeUsernameUrl = `${CHANGE_USERNAME_REQUEST_URL}/authorize?${parameters}`;
      await authContext.promptAsync({url: changeUsernameUrl});
      return true;
    } catch (error) {
      console.error('ERROR:', error);
      return false;
    }
  }

  const ChangePhoneNumber = async (authContext) => {
    try {
      const parameters = new URLSearchParams({
        code_challenge: authContext.request?.codeChallenge,
        nonce: 'defaultNonce',
        code_challenge_method: authContext.request?.codeChallengeMethod,
        redirect_uri: authContext.redirectUri,
        client_id: authContext.clientId,
        response_type: 'id_token',
        state: authContext.request?.state,
        scope: 'openid',
        prompt: 'login',
      });
      const changePhoneUrl = `${CHANGE_PHONENUMBER_REQUEST_URL}/authorize?${parameters}`;
      await authContext.promptAsync({url: changePhoneUrl});
      return true;
    } catch (error) {
      console.error('ERROR:', error);
      return false;
    }
  }

const GetOidAndAccessToken = async () => {
  try {
    const storageTokens = await AsyncStorage.getItem('tokens');
    if (storageTokens == null)
      throw 'storage tokens error';
      
    const tokenResponse = JSON.parse(storageTokens);
    const idToken = tokenResponse.idToken;
    const accessToken = tokenResponse.accessToken;
    const oid = jwtDecode(idToken).sub;

    return { oid, accessToken };
  } catch (error) {
    console.error('ERROR:', error);
    return {oid: null, accessToken: null};
  }
};

const GetUsername = async () => {
  try {
    const storageTokens = await AsyncStorage.getItem('tokens');
    if (storageTokens == null)
      throw 'storage tokens error';
      
    const tokenResponse = JSON.parse(storageTokens);
    const idToken = tokenResponse.idToken;
    return jwtDecode(idToken)["signInNames.userName"];
  } catch (error) {
    console.error('ERROR:', error);
    return null;
  }
};

const UpdatePushSubscription = async () => {
  let registration = await navigator.serviceWorker.getRegistration();
  if (!registration)
    return;

  let neighbor = await GetNeighbor();
  if (!neighbor)
    return;

  let subscription = await registration.pushManager.getSubscription();

  if (!neighbor.sendPushInd) {
    subscription && await subscription.unsubscribe();
    return console.info('Silently Unsubscribed');
  }

  if (Notification.permission !== 'granted') {
    subscription && await subscription.unsubscribe();
    await UpdateNeighborPushSubscription(neighbor, null);
    return console.info('Silently Toggled OFF Push Notifications and Unsubscribed');
  }

  if (!subscription)
    subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: APPLICATION_SERVER_KEY
    });

  await UpdateNeighborPushSubscription(neighbor, subscription);
  return console.info('Silently Subscribed');
}
  
export { SignInUp, SignOut, GetOidAndAccessToken, GetUsername, ChangePassword, ChangeUsername, ChangePhoneNumber, UpdatePushSubscription };