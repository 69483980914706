import React, { lazy, useEffect, useReducer, useRef, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { B2CAuthContext, CheckJwtTokens, ReducerForB2C, defaultB2cState, } from './context/B2CAuthContext';
import { useAuthRequest } from 'expo-auth-session';
import { NewNotificationsContext } from './context/NewNotificationsContext';
import * as Linking from 'expo-linking';
import { UpdatePushSubscription } from './utils/B2CAuth_utils';
import'./pageTranslation.js'
import LoadingPage from './components/LoadingPage.js';
import { Animated } from 'react-native-web';

const LandingScreen = lazy(() => import('./screens/LandingScreen'));
const FindFoodScreen = lazy(() => import('./screens/FindFoodScreen'));
const AccountScreen = lazy(() => import('./screens/AccountScreen'));
const ProfileScreen = lazy(() => import('./screens/ProfileScreen'));
const ClaimsScreen = lazy(() => import('./screens/ClaimsScreen'));
const NotificationScreen = lazy(() => import('./screens/NotificationScreen'));
const SettingsScreen = lazy(() => import('./screens/SettingsScreen'));
const MessageScreen = lazy(() => import('./screens/MessageScreen'));
const MessageDetailScreen = lazy(() => import('./screens/MessageDetailScreen'));
const NeighborHomeScreen = lazy(() => import('./screens/NeighborHomeScreen'));
const TermsOfUseScreen = lazy(() => import('./screens/TermsOfUseScreen'));
const PrivacyPolicyScreen = lazy(() => import('./screens/PrivacyPolicyScreen'));
const FullTermsOfUseScreen = lazy(() => import('./screens/FullTermsOfUse'));
const FullPrivacyPolicyScreen = lazy(() => import('./screens/FullPrivacyPolicy'));
const AboutScreen = lazy(() => import('./screens/AboutScreen'));
const FeedbackScreen = lazy(() => import('./screens/FeedbackScreen'));
const SurveysScreen = lazy(() => import('./screens/SurveysScreen'));
const DeliverySurveyScreen = lazy(() => import('./screens/DeliverySurveyScreen'));
const BenefitsScreen = lazy(() => import('./screens/BenefitsScreen.js'));
const EngageSurveyScreen = lazy(() => import('./screens/EngageSurveyScreen'));
const LanguageScreen = lazy(() => import('./screens/LanguageScreen'));
const MyFoodScreen = lazy(() => import('./screens/MyFoodScreen'));
const ResourceHubScreen = lazy(() => import('./screens/ResourceHubScreen'));

const Stack = createStackNavigator();

export default function App() {
  const linking = {
    prefixes: [Linking.createURL('/'), 'https://test.nectarnow.com/', 'https://nectarnow.com/'],
  };

  const [b2cState, dispatchForB2C] = useReducer(ReducerForB2C, defaultB2cState);
  const [newNotifications, setNewNotifications] = useState(0);
  const value = [newNotifications, setNewNotifications];
  const fadeRef = useRef(new Animated.Value(0)).current;
  
  useEffect(() => {
    const handleNewNotification = (event) => {
      console.log('MESSAGE:', event.data.msg);     
    }

    const handleServiceWorker = async () => {
      try {
        let registration = await navigator.serviceWorker.register('/service-worker.js');
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
        await registration.update();
        console.log('ServiceWorker updated successfully with scope: ', registration.scope);
        await UpdatePushSubscription();

      } catch (error) {
        console.log('Error: ', error);
      }
    };

    if ('serviceWorker' in navigator)
      handleServiceWorker();

    return () => {
      navigator.serviceWorker.removeEventListener('message', handleNewNotification);
    }
    
  }, []);

  const [request, , promptAsync] = useAuthRequest(
    {
      clientId: b2cState.clientId,
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      redirectUri: window.location.origin,
      extraParams: {
        nonce: 'defaultNonce',
        prompt: 'login',
        NetworkSubdomain: window.location.hostname.split('.')[0]
      }
    },
    b2cState.discovery,
  );

  b2cState.request = request;
  b2cState.promptAsync = promptAsync;

  useEffect(() => {
    console.log('LOG: Checking tokens...');
    CheckJwtTokens(b2cState, dispatchForB2C);
  }, []);

  useEffect(() => {
    if (!b2cState.isLoading) {
      console.log('LOG: animating...');
      Animated.timing(fadeRef, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true
      }).start();
    }
  }, [fadeRef, b2cState.isLoading]);

  if (b2cState.isLoading)
    return (<LoadingPage />); 

  return (
    <B2CAuthContext.Provider value={{...b2cState, dispatchForB2C}}>
      <NewNotificationsContext.Provider value={value}>
        <Animated.View style={{opacity: fadeRef, width: '100%'}}>   
          <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
            <Stack.Navigator screenOptions={{headerShown:false}}>
              {/* Logged Out Only */}
              {!b2cState.idToken &&
                <Stack.Screen name="Landing" component={LandingScreen} />
              }

              {/* Logged In Only */}
              {b2cState.idToken &&
                <>
                  {b2cState.isNewUser
                    ? (
                      <>
                        <Stack.Screen name="Settings" component={SettingsScreen} />
                        <Stack.Screen name="Home" component={NeighborHomeScreen} />                     
                      </>
                    )
                    : (
                      <>
                        <Stack.Screen name="Home" component={NeighborHomeScreen} />
                        <Stack.Screen name="Settings" component={SettingsScreen} />
                      </>
                    )
                  }
                  <Stack.Screen name="Account" component={AccountScreen} />
                  <Stack.Screen name="Profile" component={ProfileScreen} />
                  <Stack.Screen name="Notifications" component={NotificationScreen} />               
                  <Stack.Screen name="Messages" component={MessageScreen} />
                  <Stack.Screen name="MessageDetail" component={MessageDetailScreen} />
                  <Stack.Screen name="Surveys" component={SurveysScreen} />
                  <Stack.Screen name="DeliverySurvey" component={DeliverySurveyScreen} />
                  <Stack.Screen name="Benefits" component={BenefitsScreen} />
                  <Stack.Screen name="EngageSurvey" component={EngageSurveyScreen} />
                  <Stack.Screen name="Language" component={LanguageScreen} />
                  <Stack.Screen name="MyFood" component={MyFoodScreen} />
                  <Stack.Screen name="ResourceHub" component={ResourceHubScreen} />
                </>
              }

              {/* Always Viewable */}
              <Stack.Screen name="About" component={AboutScreen} />
              <Stack.Screen name="FindFood" component={FindFoodScreen} />
              <Stack.Screen name="Feedback" component={FeedbackScreen} />
              <Stack.Screen name="TermsOfUse" component={TermsOfUseScreen} />
              <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} />
              <Stack.Screen name="FullTermsOfUse" component={FullTermsOfUseScreen} />
              <Stack.Screen name="FullPrivacyPolicy" component={FullPrivacyPolicyScreen} />
            </Stack.Navigator>
          </NavigationContainer>
        </Animated.View> 
      </NewNotificationsContext.Provider>
    </B2CAuthContext.Provider>
  );
}
