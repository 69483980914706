import { refreshAsync } from "expo-auth-session";
import { createContext, useContext } from "react";
import { TokenResponse } from 'expo-auth-session';
import { CLIENT_ID, REQUEST_URL, STS_URL, API_URL } from "react-native-dotenv"
import AsyncStorage from "@react-native-async-storage/async-storage";
import { jwtDecode } from "jwt-decode";

export const defaultB2cState = {
    clientId: CLIENT_ID,
    redirectUri: window.location.origin,
    requestUri: REQUEST_URL,
    apiUri: API_URL,
    stsUri: STS_URL,
    discovery: {
        authorizationEndpoint: `${REQUEST_URL}/authorize`,
        tokenEndpoint: `${REQUEST_URL}/token`,
    },
    request: null,
    promptAsync: null,
    idToken: null,
    isNewUser: false,
    isLoading: true,
    dispatchForB2C: null,
    //tokenResponse: undefined
};

export const ReducerForB2C = (prevState, action) => {
    console.log('LOG: Dispatch:', action.type);
    switch (action.type) {
        case 'RESTORED_TOKEN':
            return {
                ...prevState,
                idToken: action.idToken,
                isLoading: false,
                isNewUser: false,
            };
        case 'SIGNED_UP':
          return {
              ...prevState,
              idToken: action.idToken,
              isNewUser: true,
          };
        case 'LOGGED_IN':
            return {
                ...prevState,
                idToken: action.idToken,
                isNewUser: false,
            };
        case 'LOGGED_OUT':
            return {
                ...prevState,
                idToken: null,
                isLoading: false,
            };
    }
};

export const GetTokensFromStorage = async () => {
  try {
    let storageTokens = await AsyncStorage.getItem('tokens');
    if (storageTokens == null)
      return null;
    
    return JSON.parse(storageTokens);   
  } catch (error) {
    console.error('ERROR: GetTokensFromStorage:', error);
    return null;
  }
}

export const GetUserClaims = async () => {
  try {
    let tokenResponse = null;
    tokenResponse = await GetTokensFromStorage();
    let decodedToken = jwtDecode(tokenResponse.idToken);
    return decodedToken;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const SetTokensFromStorage = async (tokenResponse) => {
  try {
    let storageTokens = JSON.stringify(tokenResponse);
    await AsyncStorage.setItem('tokens', storageTokens);

  } catch (error) {
    console.error(error);
  }

}

const SilentRefreshTokens = async (tokenResponse, discovery) => {
  if (TokenResponse.isTokenFresh(tokenResponse))
    return tokenResponse;

  try {
    let refreshedTokenResponse = await refreshAsync({
      refreshToken: tokenResponse.refreshToken,
      scopes: ['https://nectarnow.com/api/access_as_user', 'openid', 'profile', 'email', 'offline_access']
    }, discovery);

    await SetTokensFromStorage(refreshedTokenResponse);
    return refreshedTokenResponse;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const ValidateTokens = (b2cContext, tokenResponse) => {
  const decodedToken = jwtDecode(tokenResponse.idToken);
  if (decodedToken.aud !== b2cContext.clientId)
      return false; 
  if (decodedToken.nonce !== 'defaultNonce')
    return false;
  if (decodedToken.iss !== b2cContext.stsUri)
    return false;
  const now = Date.now() / 1000; // Needs to be in seconds
  const nbf_accounting_for_clock_skew = decodedToken.nbf - 300;
  if (nbf_accounting_for_clock_skew >= now)
    return false;
  if (decodedToken.exp <= now)
    return false;

  return true;
}

export const CheckJwtTokens = async(b2cState, dispatchForB2C) => {           
  let tokenResponse = null;
  tokenResponse = await GetTokensFromStorage();
  if (tokenResponse == null)
    return dispatchForB2C({ type: 'LOGGED_OUT' });

  tokenResponse = await SilentRefreshTokens(tokenResponse, b2cState.discovery);
  if (tokenResponse == null)
      return dispatchForB2C({ type: 'LOGGED_OUT' });

  if (!ValidateTokens(b2cState, tokenResponse))
      return dispatchForB2C({ type: 'LOGGED_OUT' });
  
  dispatchForB2C({ type: 'RESTORED_TOKEN', idToken: tokenResponse.idToken });
};

export const B2CAuthContext = createContext(defaultB2cState);
export const useB2CAuthContext = () => useContext(B2CAuthContext);